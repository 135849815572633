<template>
  <div id="statistical">
    <div class="nav">
      <img
        src="@/assets/images/statistical/return.png"
        alt=""
        class="return_img"
        @click="returnBtn"
      />
      <div class="nav-box">
        <div class="nav-sec">
          <router-link to="/those_businesses" class="nav-sec-item"
            >商户统计</router-link
          >
          <router-link to="/collector_statistics" class="nav-sec-item"
            >收运统计</router-link
          >
          <router-link to="/disposal" class="nav-sec-item"
            >处置统计</router-link
          >
          <router-link to="/grease_statistics" class="nav-sec-item"
            >油脂统计</router-link
          >
          <router-link to="/solidslag_statistics" class="nav-sec-item"
            >固渣统计</router-link
          >
        </div>
        <div class="line"></div>
      </div>
    </div>
    <div class="view-content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    returnBtn() {
      if (this.$route.query.from === "analyse") {
        this.$router.replace({ name: "Analyse" });
      } else {
        this.$router.replace({ name: "Index" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#statistical {
  padding-top: 90px;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
  .return_img {
    position: absolute;
    left: 20px;
    height: 100%;
    cursor: pointer;
  }
}
.line {
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    #1f234b 0%,
    #494f92 22%,
    #494f92 59%,
    #1f234b 100%
  );
  opacity: 0.2;
  border-radius: 1px;
}
.nav-sec {
  margin: 0 40px;
}
.nav-sec-item {
  color: #fff;
  text-decoration: none;
  height: 45px;
  line-height: 45px;
  display: inline-block;
  padding: 0 20px;
  font-size: 20px;
}
.router-link-active {
  color: rgba(0, 221, 244, 1);
  border-bottom: 2px solid rgba(0, 221, 244, 1);
}
.view-content {
  height: calc(100vh - 158px);
  overflow-y: auto;
  padding: 10px 20px 20px;
}
</style>
